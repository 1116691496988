// Types
export type RevenueCatConfig = {
  apiKey: string;
  entitlementId: string;
  baseUri: string;
};

// Global Variables

export const environment = process.env.NODE_ENV;

export const mindfeed = {
  baseUri: process.env.NEXT_PUBLIC_MINDFEED_BASE_URI || '',
};

export const growthbook = {
  apiHost: process.env.NEXT_PUBLIC_GROWTHBOOK_API_HOST || '',
  clientKey: process.env.NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY || '',
};

export const revenueCat: RevenueCatConfig = {
  apiKey: process.env.NEXT_PUBLIC_REVENUECAT_WEB_API_KEY || '',
  entitlementId: process.env.NEXT_PUBLIC_REVENUECAT_ENTITLEMENT_ID || '',
  baseUri: 'https://api.revenuecat.com/v1',
};

export const stripe = {
  publishableKey: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY || '',
};

export const marketing = {
  appStoreUri:
    'https://apps.apple.com/app/mindfeed-notes-organizer/id1466214017',
  googlePlayUri: 'https://play.google.com/store/apps/details?id=app.mindfeed',
};

// TODO: Initially env. vars. were directly used in components
// via process.env calls - we should now use this convenient helper
