import { useEffect, useState } from 'react';

// Add more browser type if needed
type BrowserType = 'safari' | 'chrome' | 'other';

export const useBrowserInfo = () => {
  const [browser, setBrowser] = useState<BrowserType>();

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();

    if (userAgent.includes('safari') && !userAgent.includes('chrome')) {
      setBrowser('safari');
    } else if (userAgent.includes('chrome')) {
      setBrowser('chrome');
    } else {
      setBrowser('other');
    }
  }, []);

  return browser;
};
