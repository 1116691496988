import { Dialog, DialogBackdrop } from '@headlessui/react';
import React, { ReactElement } from 'react';
import { closeModal } from 'services/ModalService';
import LoginModal from './LoginModal';
import { useModal } from 'hooks/useModal';
import { ModalView } from 'machines/ModalMachine';
import ActionableModal from './ActionableModal';
import SaveQuoteModal from './SaveQuoteModal';
import AppDownloadModal from './AppDownloadModal';

const Modal = (): ReactElement => {
  const { isOpen, view } = useModal();

  const renderModal = () => {
    switch (view) {
      case ModalView.Login:
        return <LoginModal />;
      case ModalView.SaveQuote:
        return <SaveQuoteModal />;
      case ModalView.Actionable:
        return <ActionableModal />;
      case ModalView.AppDownload:
        return <AppDownloadModal />;
    }
  };

  return (
    <Dialog open={isOpen} onClose={closeModal} className={styles.dialog}>
      <DialogBackdrop transition className={styles.backdrop} />
      {renderModal()}
    </Dialog>
  );
};

const styles = {
  dialog: 'relative z-10',
  backdrop:
    'fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in',
};

export default Modal;
