import React from 'react';
import SocialSignIn from 'elements/SocialSignIn';
import { useAuthentication } from 'hooks/useAuthentication';
import {
  requestToCancelAuthentication,
  requestToLogout,
} from 'services/AuthService';
import Link from 'next/link';

const Content = () => {
  const { isAuthenticating, isAuthenticated, isUnauthenticated } =
    useAuthentication();

  const handleLogoutBtnClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    requestToLogout();
  };

  const handleCancelBtnClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    requestToCancelAuthentication();
  };

  return (
    <div className=" px-5 pt-9 mb-9 !flex flex-grow items-center text-center justify-center lg:px-0 lg:justify-start lg:mb-5 lg:pt-5 bg-white">
      <div className="px-0 lg:px-28">
        <h2 className="mb-10">
          <span className="text-4xl block mb-6 leading-[45px] font-semibold text-mf-off-black-950 lg:mt-0">
            Log in or Sign up <br />
            in 1 Click!
          </span>
          <span className="text-lg sm:text-lg block text-mf-gray-600 tracking-tight">
            Keep enjoying MindFeed with the account you love.
          </span>
        </h2>

        <div>
          {(() => {
            if (isAuthenticating) {
              return <p>Authentication...</p>;
            }
            if (isAuthenticated) return <p>Authenticated</p>;
          })()}
        </div>

        <div className="flex items-center mt-10 mb-4">
          <hr className="flex-auto" />
          <span className="px-3 font-bold text-sm text-gray-400">
            GET STARTED
          </span>
          <hr className="flex-auto" />
        </div>

        <div className="mt-8 mb-12 flex justify-center items-center">
          {isUnauthenticated && <SocialSignIn />}

          {/* Logout */}
          {isAuthenticating && (
            <div className="inline-flex">
              <a
                onClick={(e) => handleCancelBtnClick(e)}
                href="#"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200"
              >
                Cancel
              </a>
            </div>
          )}

          {/* Logout */}
          {isAuthenticated && (
            <div className="ml-3 inline-flex">
              <a
                onClick={(e) => handleLogoutBtnClick(e)}
                href="#"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200"
              >
                Logout
              </a>
            </div>
          )}
        </div>

        <p className="text-sm text-mf-gray-600 text-center">
          By continuing, you agree to MindFeed&#39;s{' '}
          <Link href="/terms-of-use">Terms of Use</Link>. Read our{' '}
          <Link href="/privacy-policy">Privacy Policy.</Link>
        </p>
      </div>
    </div>
  );
};

const Login = (): JSX.Element => {
  return (
    <div className="lg:grid lg:grid-cols-2 lg:grid-rows-1 lg:gap-4 lg:min-h-screen lg:overflow-hidden">
      <div className="bg-mf-gray-50 h-full hidden lg:flex lg:justify-end lg:items-center lg:px-14">
        <div className="relative">
          <div className="absolute rounded-full bg-mf-blue-base lg:-left-[110%] lg:-bottom-[30%] xl:-left-[50%] xl:-bottom-[20%] w-[666px] h-[666px]" />
          <img
            src="/images/login-image.png"
            className="max-h-[897px] z-10 relative"
            alt="Mindfeed"
          />
        </div>
      </div>
      <Content />
    </div>
  );
};

export default Login;
