import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { MemoryBoxFormSkeleton } from './MemoryBoxFormSkeleton';
import ValidationMessage from 'elements/ValidationMessage';
import { Box } from 'models/Box';
import { useSlideOver } from 'hooks/useSlideOver';
import { useSaveBox } from 'networking/mindfeed/hooks/useSaveBox';
import { AccessLevel } from 'models/AccessLevel';
import { closeSlideOver } from 'services/SlideOverService';
import classNames from 'classnames';

interface Props {
  box?: Box;
  isLoading?: boolean;
  onSuccess?: (box: Box) => void;
}

export const MemoryBoxForm: React.FC<Props> = ({
  box,
  isLoading = false,
  onSuccess,
}) => {
  const { isOpen } = useSlideOver();

  const {
    register,
    handleSubmit,
    setValue,
    setFocus,
    reset,
    formState: { errors },
  } = useForm({ mode: 'onSubmit' });

  const onBoxCreated = (box: Box) => {
    if (onSuccess) {
      onSuccess(box);
    }
    reset();
  };

  const saveBoxMutation = useSaveBox({
    onSuccess: onBoxCreated,
  });

  const onSubmit = async (data) => {
    saveBoxMutation.mutate(data);
  };

  const boxFieldName = 'name';
  const boxFieldValidation = { required: true, minLength: 2 };

  useEffect(() => {
    // Register Access Level
    register('accessLevel');
    setValue('accessLevel', AccessLevel.Private);

    if (box) {
      Object.entries(box).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [box]);

  useEffect(() => {
    if (isOpen) {
      setFocus(boxFieldName);
    }
  }, [isOpen]);

  return isLoading || saveBoxMutation.isLoading ? (
    <MemoryBoxFormSkeleton />
  ) : (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.container}>
      {/* Top */}
      <div className={styles.top.container}>
        <label htmlFor="box-name" className={styles.top.label}>
          Name of your box
        </label>

        <input
          type="text"
          name={boxFieldName}
          id="box-name"
          autoComplete="off"
          data-1p-ignore // Ignore 1Password
          className={`${styles.top.input.default} ${
            errors[boxFieldName]
              ? styles.top.input.error.yes
              : styles.top.input.error.no
          }`}
          {...register(boxFieldName, boxFieldValidation)}
        />

        {errors?.[boxFieldName] && (
          <ValidationMessage className={styles.top.validationMessage}>
            At least 2 characters are required
          </ValidationMessage>
        )}
      </div>

      {/* Footer */}
      <div className={styles.footer.container}>
        <button
          type="button"
          className={styles.footer.btn.cancel}
          onClick={closeSlideOver}
        >
          Cancel
        </button>
        <button type="submit" className={styles.footer.btn.save}>
          {box ? 'Save' : 'Create'}
        </button>
      </div>
    </form>
  );
};

const styles = {
  container: 'border-gray-200 px-6',
  top: {
    container: 'pb-6',
    label: 'block text-sm font-normal text-gray-700 mb-1',
    input: {
      default: classNames(
        'block w-full text-sm border-[#F4F4F4] border-2 bg-[#F7FAFF] rounded-xl outline-none',
        'focus:ring-0'
      ),
      error: {
        yes: 'focus:border-[#E20035]',
        no: 'focus:border-[#161E3A]',
      },
    },
    validationMessage: 'text-right',
  },
  footer: {
    container: 'flex shrink-0 justify-end',
    btn: {
      cancel: classNames(
        'rounded-3xl border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 hover:bg-gray-50',
        'active:bg-neutral-200 focus:outline-none'
      ),
      save: classNames(
        'ml-4 inline-flex justify-center rounded-3xl border border-transparent bg-mf-blue-base py-2 px-4 text-sm font-medium',
        'text-white shadow-sm hover:bg-mf-blue-600 active:bg-blue-950 focus:outline-none'
      ),
    },
  },
};

export default MemoryBoxForm;
