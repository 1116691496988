import { useState, useEffect } from 'react';
import {
  SlideOverView,
  BoxSlideOverPayload,
  CollectionSlideOverPayload,
} from 'machines/SlideOverMachine';
import { useSlideOver } from 'hooks/useSlideOver';
import { closeSlideOver } from 'services/SlideOverService';
import BoxSlideOver from './BoxSlideOver';
import CollectionSlideOver from './CollectionSlideOver';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SlideOver = (): JSX.Element => {
  const [showContainer, setShowContainer] = useState(true);
  const { isOpen, view, slideOverState } = useSlideOver();

  const containerStyle = {
    visible: 'fixed right-0 max-w-full flex inset-y-0 pt-14',
    hidden: 'fixed right-0 max-w-full flex inset-y-none pt-14',
  };

  const drawerStyle = {
    visible:
      'w-screen max-w-md transition ease-in-out duration-500 sm:duration-300 translate-x-0',
    hidden:
      'w-screen max-w-md transition ease-in-out duration-500 sm:duration-300 translate-x-full',
  };

  const backgroundOverlayStyle = {
    visible: 'fixed inset-0 bg-black bg-opacity-20 transition-opacity',
    hidden: '',
  };

  const handleCloseBtnClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    if (isOpen) closeSlideOver();
  };

  const handleClickAway = () => {
    if (isOpen) closeSlideOver();
  };

  const renderTitle = () => {
    switch (view) {
      case SlideOverView.Box: {
        const box = (slideOverState.context?.payload as BoxSlideOverPayload)
          ?.box;
        return box ? 'Edit Box' : 'New Box';
      }
      case SlideOverView.Collection: {
        const collection = (
          slideOverState.context?.payload as CollectionSlideOverPayload
        )?.collection;
        return collection ? 'Edit Collection' : 'New Collection';
      }
    }
  };

  const renderSlideOver = () => {
    switch (view) {
      case SlideOverView.Box:
        return <BoxSlideOver />;
      case SlideOverView.Collection:
        return <CollectionSlideOver />;
    }
  };

  /**
   * The container would normally stay fixed if we didn't remove which would prevent click
   * on DOM elements behind it. To prevent this and conserve the animation, we hide the
   * container after a timeout.
   */
  useEffect(() => {
    if (isOpen === true) {
      setShowContainer(true);
      return;
    }

    const timer = setTimeout(() => {
      setShowContainer(false);
    }, 350);

    return () => clearTimeout(timer);
  }, [isOpen]);

  return (
    <div
      className={isOpen ? 'fixed overflow-hidden inset-0' : ''}
      aria-labelledby="slide-over-title"
      role="dialog"
      aria-modal="true"
    >
      <div className={isOpen ? 'absolute overflow-hidden inset-0' : ''}>
        <div
          className={
            isOpen
              ? backgroundOverlayStyle.visible
              : backgroundOverlayStyle.hidden
          }
          aria-hidden="true"
          onClick={handleClickAway}
        ></div>
        <div
          className={
            showContainer ? containerStyle.visible : containerStyle.hidden
          }
        >
          <div className={isOpen ? drawerStyle.visible : drawerStyle.hidden}>
            <div className="h-full flex-col bg-white shadow-xl overflow-y-scroll">
              <div className="p-6">
                {/* Flex container to manage title and close button */}
                <div className="flex items-center justify-between">
                  {/* Centering the title text */}
                  <div className="flex-1 flex justify-center">
                    <h2
                      className="text-base font-medium text-gray-900"
                      id="slide-over-title"
                    >
                      {renderTitle()}
                    </h2>
                  </div>

                  {/* Close button aligned to the right */}
                  <button
                    className="text-gray-400 hover:text-gray-500 focus:outline-slate-900"
                    onClick={handleCloseBtnClick}
                  >
                    <span className="sr-only">Close panel</span>
                    <FontAwesomeIcon
                      icon={faXmark}
                      className={styles.icon}
                      size="lg"
                    />
                  </button>
                </div>
              </div>

              {renderSlideOver()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const styles = {
  icon: 'text-mf-gray-600',
};
export default SlideOver;
