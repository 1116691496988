import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowUpRight,
  faCircleInfo,
  faCrown,
  faFileShield,
  faShieldKeyhole,
  faUser,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CloseButton as ClosableLink } from '@headlessui/react';
import classNames from 'classnames';
import { appRoutes } from 'config/appRoutes';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { HTMLAttributeAnchorTarget } from 'react';

interface RowProps {
  content: string;
  href: string;
  target?: HTMLAttributeAnchorTarget;
  leftIcon: IconDefinition;
  leftClassName?: string;
  rightIcon?: IconDefinition;
  rightClassName?: string;
  isActive?: boolean;
}

const Row: React.FC<RowProps> = ({
  content,
  href,
  target,
  leftIcon,
  leftClassName,
  rightIcon,
  rightClassName,
  isActive,
}) => {
  return (
    <ClosableLink
      as={Link}
      className={styles.row.container(isActive)}
      href={String(href)}
      target={target}
    >
      {/* Left */}
      <div className={styles.row.left.container}>
        <div className={styles.row.left.icon.container(isActive)}>
          <FontAwesomeIcon
            className={styles.row.left.icon.element(leftClassName, isActive)}
            icon={leftIcon}
          />
        </div>
      </div>

      {/* Middle */}
      <div className={styles.row.middle}>{content}</div>

      {/* Right */}
      {rightIcon && (
        <div className={styles.row.right.container}>
          <FontAwesomeIcon
            className={styles.row.right.icon(rightClassName)}
            icon={rightIcon}
          />
        </div>
      )}
    </ClosableLink>
  );
};

interface TitleProps {
  content: string;
}

const Title: React.FC<TitleProps> = ({ content }) => {
  return <h1 className={styles.title}>{content}</h1>;
};

const SettingsMenu = () => {
  const router = useRouter();
  const isAccountActive = router.asPath === appRoutes.SETTINGS.ACCOUNT;
  const isSubscriptionActive =
    router.asPath.startsWith(appRoutes.SETTINGS.SUBSCRIPTION) ||
    router.asPath.startsWith(appRoutes.SETTINGS.CHECKOUT.DEFAULT);

  return (
    <div className={styles.container}>
      <Row
        content="Your account"
        href={appRoutes.SETTINGS.ACCOUNT}
        leftIcon={faUser}
        isActive={isAccountActive}
      />

      <Row
        content="Subscription"
        href={appRoutes.SETTINGS.SUBSCRIPTION}
        leftIcon={faCrown}
        leftClassName="text-[#FFA42E]"
        isActive={isSubscriptionActive}
      />

      <Title content="Support" />

      <Row
        content="Help Centre"
        href={appRoutes.HELP_CENTRE}
        target="_blank"
        leftIcon={faCircleInfo}
        rightIcon={faArrowUpRight}
      />

      <Row
        content="Terms & Conditions"
        href={appRoutes.TERMS_OF_USE}
        target="_blank"
        leftIcon={faFileShield}
        rightIcon={faArrowUpRight}
      />

      <Row
        content="Privacy Policy"
        href={appRoutes.PRIVACY_POLICY}
        target="_blank"
        leftIcon={faShieldKeyhole}
        rightIcon={faArrowUpRight}
      />
    </div>
  );
};

export default SettingsMenu;

const styles = {
  container: 'flex flex-col space-y-2',
  row: {
    container: (isActive: boolean) =>
      classNames(
        'group flex flex-row items-center space-x-2 hover:bg-[#F4F4F4] p-2 rounded-lg',
        isActive ? 'bg-[#E9F4FF]' : 'bg-white'
      ),
    left: {
      container: '',
      icon: {
        container: (isActive: boolean) =>
          classNames(
            'w-10 h-10 flex items-center justify-center rounded-lg',
            isActive ? 'bg-mf-blue-base' : 'bg-[#F4F4F4]'
          ),
        element: (className: string, isActive: boolean) =>
          classNames(isActive ? 'text-white' : 'text-[#6C727E]', className),
      },
    },
    middle: 'flex-1',
    right: {
      container: 'px-2',
      icon: (className: string) => classNames('text-[#6C727E]', className),
    },
  },
  title: 'font-bold',
};
