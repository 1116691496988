import { Dialog, DialogPanel } from '@headlessui/react';
import { useModal } from 'hooks/useModal';
import React, { ReactElement } from 'react';
import {
  SquaresPlusIcon,
  ArrowDownCircleIcon,
} from '@heroicons/react/24/outline';
import { SaveQuoteModalPayload } from 'machines/ModalMachine';
import { closeModal } from 'services/ModalService';
import TextSeparator, { TextPosition } from 'elements/TextSeparator';
import { SaveQuoteForm } from 'elements/Drop/SaveQuoteForm';

const SaveQuoteModal = (): ReactElement => {
  const { payload } = useModal() as { payload: SaveQuoteModalPayload };
  const showTitle = false;

  return (
    <div className={styles.outer}>
      <div className={styles.inner}>
        <DialogPanel transition className={styles.panel}>
          <div>
            {showTitle && (
              <div className={styles.header.container}>
                <div className={styles.header.iconContainer}>
                  <SquaresPlusIcon
                    className={styles.header.icon}
                    aria-hidden="true"
                  />
                </div>

                <Dialog.Title as="h3" className={styles.header.title}>
                  Add to my Memory Box
                </Dialog.Title>
              </div>
            )}

            <div className={styles.quote.container}>
              <div className={styles.quote.background}>
                <div className={styles.quote.innerContainer}>
                  <blockquote>
                    <div className={styles.quote.content}>
                      <svg
                        className={styles.quote.icon}
                        fill="currentColor"
                        viewBox="0 0 32 32"
                        aria-hidden="true"
                      >
                        <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                      </svg>
                      <p className={styles.quote.text}>
                        {payload.quote.content}
                      </p>
                    </div>
                    <footer className={styles.quote.footer}>
                      <TextSeparator
                        text={payload.quote.author.name}
                        textPosition={TextPosition.Center}
                        textStyle={styles.quote.footerText}
                      />
                    </footer>
                  </blockquote>
                </div>
              </div>
            </div>

            <div className={styles.arrow.container}>
              <ArrowDownCircleIcon
                className={styles.arrow.icon}
                aria-hidden="true"
              />
            </div>

            <SaveQuoteForm
              quote={payload.quote}
              onCancel={closeModal}
              onSuccess={closeModal}
            />
          </div>
        </DialogPanel>
      </div>
    </div>
  );
};

export default SaveQuoteModal;

const styles = {
  outer: 'fixed inset-0 z-10 w-screen overflow-y-auto',
  inner:
    'flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0',
  panel:
    'relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95',

  header: {
    container: 'flex row items-center justify-center mt-5 mb-3',
    iconContainer:
      'mr-2 flex h-12 w-12 items-center justify-center rounded-full bg-blue-100',
    icon: 'h-6 w-6 text-blue-600',
    title: 'text-lg font-medium leading-6 text-gray-900',
  },

  quote: {
    container: 'text-center',
    background:
      'bg-gradient-to-r from-mf-blue-600 to-blue-600 pb-4 lg:relative lg:z-10',
    innerContainer:
      'mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0',
    content: 'px-6 pt-2',
    icon: 'h-12 w-12 text-white opacity-25',
    text: 'mt-2 text-2xl font-medium text-white',
    footer: 'mt-6 px-6',
    footerText: 'text-base font-medium text-white',
  },

  arrow: {
    container: 'text-center',
    icon: 'h-8 w-8 text-blue-600 mx-auto my-2',
  },
};
