import React, { FC, ReactElement } from 'react';
import classNames from 'classnames';
import { notify, NotificationType } from 'services/NotificationService';
import { Collection as CollectionModel } from 'models/Collection';
import useDeleteCollection from 'hooks/useDeleteCollection';
import { openCollectionSlideOver } from 'services/SlideOverService';
import { appRoutes } from 'config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleInfo,
  faEllipsis,
  faPen,
  faTrash,
} from '@fortawesome/pro-solid-svg-icons';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { useXBox } from 'xstate/hooks/useXBox';
import Router from 'next/router';
import { ActionableModalPayload } from 'machines/ModalMachine';
import { openActionableModal } from 'services/ModalService';

interface FakeCollectionProps {
  isSelected?: boolean;
  onSelect?: () => void;
}

const FakeCollection: FC<FakeCollectionProps> = ({
  isSelected = false,
  onSelect,
}): ReactElement => {
  const { getSelectedBox } = useXBox();
  const selectedBox = getSelectedBox();

  const link = selectedBox?.id
    ? `${appRoutes.MEMORY_BOX}/${selectedBox.id}`
    : appRoutes.MEMORY_BOX;

  const onContainerClick = () => {
    Router.push(link);
    if (onSelect) {
      onSelect();
    }
  };

  return (
    <div className={styles.container(isSelected)} onClick={onContainerClick}>
      <span className={styles.left(isSelected)}>#</span>
      <span className={styles.middle}>All</span>
    </div>
  );
};

interface CollectionProps {
  collection: CollectionModel;
  isSelected?: boolean;
  onSelect?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
}

const Collection: FC<CollectionProps> = ({
  collection,
  isSelected = false,
  onSelect,
  onEdit,
  onDelete,
}): ReactElement => {
  const deleteCollectionMutation = useDeleteCollection({
    onSuccess: () => {
      // Notify User when collection is deleted
      notify(NotificationType.COLLECTION_DELETED);
    },
    removeOnSuccess: false,
  });

  const { getSelectedBox } = useXBox();
  const selectedBox = getSelectedBox();

  const link = selectedBox?.id
    ? `${appRoutes.MEMORY_BOX}/${selectedBox.id}/c/${collection.id}`
    : appRoutes.MEMORY_BOX;

  const openDeleteModal = () => {
    const bodyElement = (
      <div className="my-5">
        <span className="block my-4">
          Are you sure you would like to delete this collection?
        </span>
        <div className="bg-blue-100 rounded-lg p-3 mt-4 flex flex-row justify-center items-start space-x-3">
          <FontAwesomeIcon icon={faCircleInfo} className="text-blue-600 pt-1" />
          <span className="mb-2">
            Your memos will not be lost&#59; they will be moved to the
            &quot;All&quot; collection.
          </span>
        </div>
      </div>
    );

    const payload: ActionableModalPayload = {
      title: `Delete Collection "${collection.name}"`,
      bodyElement,
      onAccept: () => deleteCollectionMutation.mutate(collection.id),
      acceptLabel: 'Delete',
    };
    openActionableModal(payload);
  };

  const onContainerClick = () => {
    Router.push(link);
    if (onSelect) {
      onSelect();
    }
  };

  const onEditClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    openCollectionSlideOver(collection);

    if (onEdit) {
      onEdit();
    }
  };

  const onDeleteClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    openDeleteModal();

    if (onDelete) {
      onDelete();
    }
  };

  return (
    // We use onClick instead of a <Link /> attribute to prevent clicks on
    // MenuButton from triggering the parent link
    <div onClick={onContainerClick}>
      <div key={collection.id} className={styles.container(isSelected)}>
        <span className={styles.left(isSelected)}>
          {collection.emoji ? collection.emoji : '#'}
        </span>
        <span className={styles.middle}>{collection.name}</span>
        <div className={styles.right.container}>
          <Menu>
            <MenuButton
              className={styles.right.btn.container(isSelected)}
              onClick={(event) => event.stopPropagation()}
            >
              <FontAwesomeIcon
                icon={faEllipsis}
                className={styles.right.btn.icon}
              />
            </MenuButton>
            <MenuItems
              transition
              anchor="bottom end"
              className={styles.right.items.container}
            >
              <MenuItem>
                <button className={styles.right.item.btn} onClick={onEditClick}>
                  <FontAwesomeIcon
                    icon={faPen}
                    className={styles.right.item.icon}
                  />
                  Edit
                </button>
              </MenuItem>
              <MenuItem>
                <button
                  className={styles.right.item.btn}
                  onClick={onDeleteClick}
                  disabled={deleteCollectionMutation.isLoading}
                >
                  <FontAwesomeIcon
                    icon={faTrash}
                    className={styles.right.item.icon}
                  />
                  Delete
                </button>
              </MenuItem>
            </MenuItems>
          </Menu>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: (isSelected: boolean) =>
    classNames(
      'group rounded-[10px] flex items-center hover:bg-gray-100 text-gray-900 hover:text-gray-900 cursor-pointer',
      'flex truncate items-center w-full py-1.5 pl-2 border-0',
      isSelected ? 'bg-blue-100' : 'bg-white'
    ),
  left: (isSelected: boolean) =>
    classNames(
      'flex-none rounded-[10px] flex items-center justify-center w-11 h-11',
      isSelected
        ? 'text-white bg-mf-blue-base active:bg-blue-100'
        : 'bg-gray-100 text-gray-900 hover:text-gray-900 hover:bg-blue-50'
    ),
  middle: 'flex-1 truncate font-normal px-3 text-slate-800 text-normal',
  right: {
    container: 'relative mr-2',
    btn: {
      container: (isSelected: boolean) =>
        classNames(
          'inline-flex items-center rounded-md hover:bg-gray-200',
          'py-1.5 px-1 font-semibold focus:outline-none data-[hover]:bg-gray-200 data-[open]:bg-gray-200',
          isSelected && 'visible lg:invisible lg:group-hover:visible',
          !isSelected && 'invisible lg:group-hover:visible'
        ),
      icon: 'fa-fw',
    },
    items: {
      container: classNames(
        'w-40 mt-1.5 origin-top-right rounded-xl bg-white shadow shadow-md p-1 text-sm/6 text-black z-30',
        'transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none',
        'data-[closed]:scale-95 data-[closed]:opacity-0'
      ),
    },
    item: {
      btn: 'group flex w-full items-center gap-2 rounded-lg py-2 px-3 data-[focus]:bg-gray-100',
      icon: 'fa-fw size-4 text-gray-500',
    },
  },
  text: 'ml-3',
};

export { Collection, FakeCollection };
