import React, { ReactElement } from 'react';
import { reportUserClickedOnEvent } from 'utils/analytics/segment';
import {
  requestAuthenticateWithApple,
  requestAuthenticateWithFacebook,
  requestAuthenticateWithGoogle,
} from 'services/AuthService';
import { useRouter } from 'next/router';
import { appRoutes } from 'config';
import SignInBtn from 'elements/SignInBtn';
import { useBrowserInfo } from 'hooks';
import { AuthMethodType } from 'models/Auth';

const SocialSignIn = (): ReactElement => {
  const router = useRouter();
  const browser = useBrowserInfo();

  // The user should be redirected if currently on the Login page
  const redirectUrl =
    router.pathname === appRoutes.LOGIN ? appRoutes.MEMORY_BOX : null;

  const handleGoogleBtnClick = () => {
    reportUserClickedOnEvent('Sign in with Google', 'Login Page');
    // TODO: RedirectUrl should probably not belong to the View, that's business logic that should
    // be handled seperately, maybe a seperate state machine logic for that
    // We should maybe use the useAuthenticationObserver and when login has happened then redirect the
    // user appropriately.
    requestAuthenticateWithGoogle(redirectUrl);
  };

  const handleFacebookBtnClick = () => {
    reportUserClickedOnEvent('Sign in with Facebook', 'Login Page');
    // TODO: RedirectUrl should probably not belong to the View, that's business logic that should
    // be handled seperately, maybe a seperate state machine logic for that
    // We should maybe use the useAuthenticationObserver and when login has happened then redirect the
    // user appropriately.
    requestAuthenticateWithFacebook(redirectUrl);
  };

  const handleAppleBtnClick = () => {
    reportUserClickedOnEvent('Sign in with Apple', 'Login Page');
    // TODO: RedirectUrl should probably not belong to the View, that's business logic that should
    // be handled seperately, maybe a seperate state machine logic for that
    // We should maybe use the useAuthenticationObserver and when login has happened then redirect the
    // user appropriately.
    requestAuthenticateWithApple(redirectUrl);
  };

  return (
    <div className={styles.container}>
      {browser == 'safari' ? (
        <>
          <SignInBtn
            authMethod={AuthMethodType.Apple}
            onClick={handleAppleBtnClick}
          />
          <SignInBtn
            authMethod={AuthMethodType.Google}
            onClick={handleGoogleBtnClick}
          />
        </>
      ) : (
        <>
          <SignInBtn
            authMethod={AuthMethodType.Google}
            onClick={handleGoogleBtnClick}
          />
          <SignInBtn
            authMethod={AuthMethodType.Apple}
            onClick={handleAppleBtnClick}
          />
        </>
      )}
      <SignInBtn
        authMethod={AuthMethodType.Facebook}
        onClick={handleFacebookBtnClick}
      />
    </div>
  );
};

export default SocialSignIn;

const styles = {
  container: 'flex flex-col items-center mx-auto space-y-5',
};
