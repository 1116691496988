import { DialogPanel, DialogTitle } from '@headlessui/react';
import React, { ReactElement } from 'react';
import { closeModal } from 'services/ModalService';
import classNames from 'classnames';
import { isIOS } from 'react-device-detect';
import { marketing } from 'config/global';
import Link from 'next/link';

const AppDownloadModal = (): ReactElement => (
  <div className={styles.outer}>
    <div className={styles.inner}>
      <DialogPanel transition className={styles.panel.container}>
        <DialogTitle as="h3" className={styles.panel.header}>
          <img
            src="/logomark-blue.svg"
            className="mr-4"
            alt="MindFeed logomark in blue on MindFeed website"
          />
          Continue in the app
        </DialogTitle>
        <div className={styles.panel.body.root}>
          <p className={styles.panel.body.text}>
            Enjoy more features, capture, connect, and organize your thoughts
            effortlessly!
          </p>
        </div>
        <div className={styles.panel.footer.container}>
          <button
            type="button"
            onClick={() => {
              closeModal();
            }}
            className={styles.panel.footer.buttons.stay}
          >
            Stay on web
          </button>
          <Link href={isIOS ? marketing.appStoreUri : marketing.googlePlayUri}>
            <button
              type="button"
              onClick={closeModal}
              className={styles.panel.footer.buttons.download}
            >
              Get App
            </button>
          </Link>
        </div>
      </DialogPanel>
    </div>
  </div>
);

const styles = {
  outer: 'fixed inset-0 z-10 w-screen overflow-y-auto sm:hidden',
  inner: 'flex min-h-full items-end justify-center p-0',
  panel: {
    container: classNames(
      'relative w-full overflow-hidden p-5',
      'bg-white transform transition-all text-left shadow-xl rounded-[20px] rounded-bl-none rounded-br-none',
      'data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in'
    ),
    header: 'flex items-center text-2xl text-mf-offblack-950 mb-4 font-bold',
    body: {
      root: 'mb-6',
      text: 'text-mf-gray-600 text-md',
    },
    footer: {
      container: 'flex justify-end items-center gap-2',
      buttons: {
        stay: 'justify-center rounded-full bg-white px-4 py-2.5 text-sm font-semibold text-mf-offblack-950 hover:bg-gray-50',
        download:
          'justify-center rounded-full bg-mf-blue-base text-white px-4 py-2.5 text-sm font-semibold hover:bg-mf-blue-600',
      },
    },
  },
};

export default AppDownloadModal;
