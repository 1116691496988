import React, { FC } from 'react';
import { AuthMethodType } from 'models/Auth';
import AppleLogo from '../../../public/icons/icon-apple-dark.svg';
import FacebookLogo from '../../../public/icons/icon-facebook-dark.svg';
import GoogleLogo from '../../../public/icons/icon-google.svg';
import classNames from 'classnames';

interface Props {
  authMethod?: AuthMethodType;
  onClick: () => void;
}

const SignInBtn: FC<Props> = ({ authMethod, onClick }): JSX.Element => {
  const handleBtnClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    onClick();
  };

  const Logo = (): JSX.Element => {
    switch (authMethod) {
      case AuthMethodType.Apple:
        return <AppleLogo width={24} height={24} />;
      case AuthMethodType.Facebook:
        return <FacebookLogo width={24} height={24} />;
      default:
        return <GoogleLogo width={24} height={24} />;
    }
  };

  const Title = ({ className }): JSX.Element => {
    switch (authMethod) {
      case AuthMethodType.Apple:
        return <span className={className}>Sign in with Apple</span>;
      case AuthMethodType.Facebook:
        return <span className={className}>Sign in with Facebook</span>;
      default:
        return <span className={className}>Sign in with Google</span>;
    }
  };

  return (
    <button className={styles.container(authMethod)} onClick={handleBtnClick}>
      <Logo />
      <Title className={styles.title} />
    </button>
  );
};

export default SignInBtn;

const styles = {
  container: (type: AuthMethodType) => {
    let bgColor: string = '';
    let fontColor: string = '';
    let borderColor: string = '';
    switch (type) {
      case AuthMethodType.Apple:
        bgColor = 'bg-black';
        fontColor = 'text-white';
        borderColor = 'border-black';
        break;
      case AuthMethodType.Facebook:
        bgColor = 'bg-fb-blue';
        fontColor = 'text-white';
        borderColor = 'border-fb-blue';
        break;
      default:
        bgColor = 'bg-white';
        fontColor = 'text-mf-offblack-950';
        borderColor = 'border-mf-gray-200';
        break;
    }
    return classNames(
      'flex flex-row w-72 rounded-full border border-1 justify-center',
      'items-center space-x-2 p-2.5',
      bgColor,
      fontColor,
      borderColor
    );
  },
  title: 'text-sm font-semibold',
};
